/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// console.log('Hello World from Webpacker');

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
const images = require.context("../images", true)
const imagePath = (name) => images(name, true)

import "stylesheets/application.scss";

global.$ = require('jquery');
global.jQuery = $;

// use special jquery-ui package to avoid problems loading css wih images
//require('webpack-jquery-ui');
require('webpack-jquery-ui/effects');
//require('webpack-jquery-ui/css');

//global.moment = require('moment');

require('tempusdominus-bootstrap-4');
import 'moment-timezone';
require("select2");
require("select2/dist/js/i18n/de");
//require("@rails/ujs").start();
import Rails from '@rails/ujs';
Rails.start();
global.Rails = Rails;

import 'bootstrap/dist/js/bootstrap';
import { popoverWhiteList } from "../src/bootstrap-sanitizer.js";
global.popoverWhiteList = popoverWhiteList;

// https://datatables.net
//require('datatables.net-bs4')();
import 'datatables.net-fixedcolumns-bs4';
import 'datatables.net-select-bs4/js/select.bootstrap4.js';
import 'datatables.net-buttons-bs4/js/buttons.bootstrap4.js';
import 'datatables.net-buttons/js/buttons.colVis.js';
import 'datatables.net-buttons/js/buttons.html5.js';
// import 'datatables.net-buttons/js/buttons.print.js';
import 'datatables.net-searchpanes-bs4/js/searchPanes.bootstrap4.js';

import Sortable from 'sortablejs';
global.SortableJS = Sortable;

// FullCalendar
import { Calendar } from '@fullcalendar/core';
global.FullCalendar = Calendar;
import calendar_de from "@fullcalendar/core/locales/de";
global.calendar_de = calendar_de;
import dayGridPlugin from "@fullcalendar/daygrid";
global.dayGridPlugin = dayGridPlugin;
import timeGridPlugin from "@fullcalendar/timegrid";
global.timeGridPlugin = timeGridPlugin;
import bootstrapPlugin from '@fullcalendar/bootstrap';
global.bootstrapPlugin = bootstrapPlugin;
import interactionPlugin, { Draggable } from "@fullcalendar/interaction";
global.interactionPlugin = interactionPlugin;
global.FullCalendarDraggable = Draggable;

import fc_extras from "../src/fullcalendar-extras.js";
global.fc_extras = fc_extras;
import "../src/fullcalendar-webcomponent";

global.remote_forms = require("../src/remote-forms.js");

global.common = require("../src/common.js");

global.dt_picker_extras = require("../src/datetimepicker-extras.js");

global.absences = require("../src/update-ooo.js");

global.users = require("../src/user-roles.js");

import {LiveCapture} from "../src/livecapture-webcomponent.js"
global.LiveCapture = LiveCapture;

import run_editor from "../elm/run_editor/src/RunEditor";
global.run_editor = run_editor

global.run_editor_extras = require("../src/run-editor-extras.js");
global.run_editor_app = null;

import machine_data_downloader from "../elm/machine_data_downloader/src/MachineDataDownloader";
global.machine_data_downloader = machine_data_downloader

global.Routes = require("../src/routes.js.erb")
global.material_lots_table = require("../src/material-lots-table.js")
global.material_deliveries_table = require("../src/material-deliveries-table.js")
global.material_import = require("../src/material-import.js")

require('@openfonts/roboto_latin')
require('@openfonts/roboto-mono_latin')

require("channels");
