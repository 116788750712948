function init_datetimepicker(container) {

  $('.datetimepicker-input.datetimepicker', container).each( function() {
    try {
      // Workaround: clear value because bootstrap4-datetime-picker-rails sometimes fails
      // if value is already set (recursion error) and set it back after initialisation
      var val = $(this).val();
      $(this).val('');
      $(this).data({target: "#" + this.id});
      $(this).datetimepicker({
        locale: 'de',
        stepping: $(this).attr("data-stepping"),
        sideBySide: true,
        allowInputToggle: true,
        format: "ddd DD.MM.YYYY HH:mm",
        widgetPositioning: {
          horizontal: 'auto',
          vertical: 'bottom'  // Workaround: Because widget might be cut on top if shown in a modal
        }

      });
      $(this).val(val);
    } catch(err) {
      console.log(err);
    }
  });

  $('.datetimepicker-input.datepicker', container).each( function() {
    try {
      var val = $(this).val();
      $(this).val('');
      $(this).data({target: "#" + this.id});
      $(this).datetimepicker({
        locale: 'de',
        allowInputToggle: true,
        format: "ddd DD.MM.YYYY",
        widgetPositioning: {
          horizontal: 'auto',
          vertical: 'bottom'  // Workaround: Because widget might be cut on top if shown in a modal
        }
      });
      $(this).val(val);
    } catch(err) {
      console.log(err);
    }
  });

}

module.exports = { init_datetimepicker }
