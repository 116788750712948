function run_editor_init(elem_id) {
  // see: https://benpaulhanna.com/basic-html5-drag-and-drop-with-elm.html
  // we need this for dnd in firefox
  document.body.addEventListener('dragstart', function (event) {
    event.dataTransfer.setData('text/plain', null);
  });
  var element = document.getElementById(elem_id);
  var authenticity_token = $(element).data("authenticity-token");
  var readonly = $(element).data("readonly");
  var run_state = $(element).data("run-state");
  var execution_enabled = $(element).data("execution-enabled");
  var debug    = $(element).data("debug");
  var mode     = $(element).data("mode");
  var id       = $(element).data("id");
  var run_item_id       = $(element).data("run-item-id");
  var endpoint_master_data = $(element).data("endpoint-master-data");
  var endpoint_working_data = $(element).data("endpoint-working-data");
  var endpoint_run_item = $(element).data("endpoint-run-item");
  var endpoint_refresh_withdrawals = $(element).data("endpoint-refresh-withdrawals");
  var endpoint_item_file_upload = $(element).data("endpoint-item-file-upload");
  var lock_version = $(element).data("lock-version");
  var can_create_reservation = $(element).data("can-create-reservation");
  var current_user = $(element).data("current-user");
  var logo_path = $(element).data("logo-path");

  var choose_material_callback = null;

  run_editor_app = run_editor.Elm.RunEditor.init(
      { node: element,
        flags: { csrfXhrToken: authenticity_token,
          readonly: readonly,
          run_state: run_state,
          execution_enabled: execution_enabled,
          debug: debug,
          mode: mode,
          id: id,
          run_item_id: run_item_id,
          endpoint_master_data: endpoint_master_data,
          endpoint_working_data: endpoint_working_data,
          endpoint_run_item: endpoint_run_item,
          endpoint_refresh_withdrawals: endpoint_refresh_withdrawals,
          endpoint_item_file_upload: endpoint_item_file_upload,
          lock_version: lock_version,
          can_create_reservation: can_create_reservation,
          current_user: current_user,
          logo_path: logo_path
        }
      }
  );

  var [ base_url, params ] = window.location.href.split("?");
  if ( base_url && params && params.length > 0) {
    window.history.replaceState('without_params', '', base_url);
  }

  run_editor_app.ports.showReservation.subscribe(function(res_id) {
    if (res_id == "") {
      alert("Error: Reservation ID was empty!");
    } else {
      var url = Routes.reservation_path(res_id);
      if (url)
        common.openModal(url);
    }
  });

  run_editor_app.ports.showMaterialList.subscribe(function({ run_id, parameter_type, item_id, param_id, group_id, material_type_name, filter }) {
    if (material_type_name == "") {
      alert("Error: MaterialType name was empty!");
    } else {
      var url = Routes.run_materials_path({
        run_id: run_id,
        parameter_type: parameter_type,
        item_id: item_id,
        param_id: param_id,
        group_id: group_id,
        material_type_name: material_type_name,
	filter: filter
      });
      common.openModal(url, "materials-modal", "xxl");
    }
  });

  run_editor_app.ports.openMaterialLot.subscribe(function(url) {
    if (url) {
      common.openModal(url, null, "xxl");
    } else {
      alert("Error: MaterialType name was empty!");
    }
  });

  run_editor_app.ports.modalOpen.subscribe(function(open) {
    // manually add class "modal-open" to body tag (outside elm div)
    // to prevent scrolling the main page when a modal is shown
    if (open) {
      $("body").addClass("modal-open");
    } else {
      $("body").removeClass("modal-open");
    }
  });
}

function open_material_lot(material_delivery_id, material_lot_id, run_id, item_id, param_id, group_id) {
  // open material_lot in new modal
  var url = Routes.material_delivery_material_lot_path(
    material_delivery_id,
    material_lot_id,
    { modal: true, run_id: run_id, item_id: item_id, param_id: param_id, group_id: group_id }
  );

  common.openModal(url, "material-lot-modal-" + material_lot_id, "xxl");
}

function refresh_withdrawals(item_id, param_id) {
  if (!run_editor_app) return;

  console.log(`Refreshing withdrawals for item ${item_id} and param ${param_id}...`);
  run_editor_app.ports.refreshWithdrawals.send([item_id, param_id]);
}

function refresh_run_editor() {
  if (!run_editor_app) return;
  run_editor_app.ports.refresh.send(null);
}

module.exports = { open_material_lot, run_editor_init, refresh_run_editor, refresh_withdrawals }
