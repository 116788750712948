export class LiveCapture extends HTMLElement {

  image_template() {
    return `
      <div class="livecapture-image">
        <button class="livecapture-actions-start btn btn-primary mr-3"><i class="fas fa-camera"></i></button>
        <div class="livecapture-inner">
          <div class="livecapture-actions rounded-pill">
            <button class="livecapture-actions-capture btn btn-danger rounded-circle" hidden=""><i class="fas fa-camera"></i></button>
            <button class="livecapture-actions-send btn btn-secondary rounded-circle" hidden=""><i class="fas fa-upload"></i></button>
            <button class="livecapture-actions-close btn btn-secondary rounded-circle" hidden=""><i class="fas fa-times"></i></button>
          </div>
          <div class="livecapture-preview" hidden=""></div>
          <div class="livecapture-area" hidden="">
            <video class="livecapture-stream" muted>Stream nicht verfügbar</video>
          </div>
        </div>
        <input type="file" class="livecapture-input"></input>
      </div>
    `;
  }

  video_template() {
    return `
      <div class="livecapture-video">
        <button class="livecapture-actions-start btn btn-primary mr-3"><i class="fas fa-video"></i></button>
        <div class="livecapture-inner">
          <div class="livecapture-actions rounded-pill">
            <button class="livecapture-actions-capture btn btn-danger rounded-circle" hidden=""><i class="fas fa-circle"></i></button>
            <button class="livecapture-actions-stop btn btn-danger rounded-circle" hidden=""><i class="fas fa-stop"></i></button>
            <button class="livecapture-actions-send btn btn-secondary rounded-circle" hidden=""><i class="fas fa-upload"></i></button>
            <button class="livecapture-actions-close btn btn-secondary rounded-circle" hidden=""><i class="fas fa-times"></i></button>
          </div>
          <div class="livecapture-preview" hidden=""></div>
          <div class="livecapture-area" hidden="">
            <video class="livecapture-stream" muted>Stream nicht verfügbar</video>
          </div>
        </div>
        <input type="file" class="livecapture-input"></input>
      </div>
    `;
  }

  audio_template() {
    return `
      <div class="livecapture-audio">
        <button class="livecapture-actions-start btn btn-primary mr-3"><i class="fas fa-microphone"></i></button>
        <div class="livecapture-inner">
          <div class="livecapture-actions rounded-pill">
            <button class="livecapture-actions-capture btn btn-danger rounded-circle" hidden=""><i class="fas fa-circle"></i></button>
            <button class="livecapture-actions-stop btn btn-danger rounded-circle" hidden=""><i class="fas fa-stop"></i></button>
            <button class="livecapture-actions-send btn btn-secondary rounded-circle" hidden=""><i class="fas fa-upload"></i></button>
            <button class="livecapture-actions-close btn btn-secondary rounded-circle" hidden=""><i class="fas fa-times"></i></button>
          </div>
          <div class="livecapture-preview" hidden=""></div>
          <div class="livecapture-area" hidden="">
            <audio class="livecapture-stream" muted>Stream nicht verfügbar</audio>
          </div>
        </div>
        <input type="file" class="livecapture-input"></input>
      </div>
    `;
  }

  connectedCallback() {
    let startButton = null;

    switch (this.getAttribute("type")) {
    case 'image':
      this.innerHTML = this.image_template();
      this
	.querySelector(".livecapture-actions-start")
	.addEventListener("click", () => this.init_image());
      break;
    case 'video':
      this.innerHTML = this.video_template();
      this
	.querySelector(".livecapture-actions-start")
	.addEventListener("click", () => this.init_video());
      break;
    case 'audio':
      this.innerHTML = this.audio_template();
      this
	.querySelector(".livecapture-actions-start")
	.addEventListener("click", () => this.init_audio());
      break;
    }

    this.preview = this.querySelector(".livecapture-preview");
    this.inputfield = this.querySelector(".livecapture-input");
    this.liveCaptureArea = this.querySelector('.livecapture-area');
    this.closeButton = this.querySelector(".livecapture-actions-close");
    this.sendButton = this.querySelector(".livecapture-actions-send");
    this.startButton = this.querySelector(".livecapture-actions-capture");
    this.stopButton = this.querySelector(".livecapture-actions-stop");
    this.stream = this.querySelector(".livecapture-stream");

    this.closeButton.addEventListener("click", () => this.close());
    this.sendButton.addEventListener("click", () => this.dispatchData());
  }

  close() {
    this.querySelector(".livecapture-inner").classList.remove("active");
    this.closeButton.hidden = true;
  }

  init_common() {
    this.querySelector(".livecapture-inner").classList.add("active");
    this.querySelector(".livecapture-actions-close").hidden = false;
    this.querySelector(".livecapture-actions-capture").hidden = false;
  }

  init_image() {
    this.init_common();
    var canvas = null;
    this.stream.addEventListener("loadedmetadata", () => {
      canvas = new OffscreenCanvas(this.stream.videoWidth, this.stream.videoHeight);
    });
    navigator.mediaDevices
      .getUserMedia({ video: { facingMode: "environment",
			       width: { min: 1024, ideal: 1920, max: 1920 } },
		      audio: true })
      .then((media) => {
        this.stream.srcObject = media;
        this.stream.play();
        this.mediaRecorder = this.initializeMediaRecorder(media);
	this.liveCaptureArea.hidden = false;
      });

    this.startButton
      .onclick = async (ev) => {
	const ctx = canvas.getContext('2d');
	ctx.drawImage(this.stream, 0, 0, canvas.width, canvas.height);
	let blob = await canvas.convertToBlob();
	this.updateInputFile(blob, "snapshot.png", "image/png");
	this.preview.hidden = false;
	this.liveCaptureArea.hidden = true;
	this.sendButton.hidden = false;
      }
  }

  init_video() {
    this.init_common();

    navigator.mediaDevices
      .getUserMedia({ video: { facingMode: "environment",
			       width: { min: 1024, ideal: 1920, max: 1920 } },
		      audio: true })
      .then((media) => {
        this.stream.srcObject = media;
        this.stream.play();
        this.mediaRecorder = this.initializeMediaRecorder(media);
	this.liveCaptureArea.hidden = false;
      });

    this.stopButton.onclick = async (ev) => {
      this.mediaRecorder.stop();
      this.startButton.hidden = false;
      this.stopButton.hidden = true;
      this.preview.hidden = false;
      this.liveCaptureArea.hidden = true;
      this.sendButton.hidden = false;
    }

    this.startButton.onclick = async (ev) => {
      this.mediaRecorder.start();
      this.startButton.hidden = true;
      this.stopButton.hidden = false;
      this.preview.hidden = true;
      this.liveCaptureArea.hidden = false;
      this.sendButton.hidden = true;
    }
  }

  init_audio() {
    this.init_common();

    navigator.mediaDevices
      .getUserMedia({ video: false, audio: true })
      .then((media) => {
        this.stream.srcObject = media;
        this.stream.play();
        this.mediaRecorder = this.initializeMediaRecorder(media);
	this.liveCaptureArea.hidden = false;
      });

    this.stopButton.onclick = async (ev) => {
      this.mediaRecorder.stop();
      this.startButton.hidden = false;
      this.stopButton.hidden = true;
      this.preview.hidden = false;
      this.liveCaptureArea.hidden = true;
      this.sendButton.hidden = false;
    }

    this.startButton.onclick = async (ev) => {
      this.mediaRecorder.start();
      this.startButton.hidden = true;
      this.stopButton.hidden = false;
      this.preview.hidden = true;
      this.liveCaptureArea.hidden = false;
      this.sendButton.hidden = true;
    }
  }

  showPreview() {
    let el;
    switch (this.getAttribute("type")) {
    case 'image':
      el = document.createElement('img');
      break;
    case 'video':
      el = document.createElement('video');
      el.controls = true;
      break;
    case 'audio':
      el = document.createElement('audio');
      el.controls = true;
      break;
    }
    el.src = URL.createObjectURL(this.inputfield.files[0]);

    this.preview.innerHTML = '';
    this.preview.appendChild(el);
  }

  dispatchData() {
    let src = URL.createObjectURL(this.inputfield.files[0]);
    const event = new CustomEvent("capturetaken", {
      detail: { src: src, file: this.inputfield.files[0] }
    });
    this.dispatchEvent(event);
    this.close();
  }

  toggleLiveCapture() {
    this.liveCaptureArea.hidden = !this.liveCaptureArea.hidden;
  }

  updateInputFile(blob, filename, filetype) {
    let file = new File([blob], filename, { type: filetype });
    let d = new DataTransfer();
    d.items.add(file);
    this.inputfield.files = d.files;
    this.showPreview();
  }

  initializeMediaRecorder(stream) {
    let media = new MediaRecorder(stream);
    this.chunks = [];
    media.addEventListener("dataavailable", (e) => this.chunks.push(e.data));
    media.addEventListener("stop", () => {
      var blob = new Blob(this.chunks, { 'type' : 'video/ogv; codecs=opus' });
      this.updateInputFile(blob, "video.ogv", "video/ogv");
      this.showPreview();
    });
    return media;
  }
}

customElements.define('live-capture', LiveCapture);
